.titl {
  a {
    text-decoration: none;
    color: black;
  }

  :hover {
    color: darkgray;
    cursor: pointer;
  }
}

.date {
  margin-top: 0.25rem;
}

.charmbar {
  margin-top: 0.25rem;
}

.desc {
  margin-top: 1rem;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
