@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700);
.navit-char{height:1rem;width:1rem;text-decoration:none;display:flex;justify-content:center;align-items:center;color:#ffffff;background-color:#272727;border:2px solid #212629;border-radius:1em;padding:1.25rem;box-shadow:0 -15px 15px rgba(255,255,255,0.05),inset 0 -15px 15px rgba(255,255,255,0.05),0 -5px 5px rgba(0,0,0,0.2),inset 0 15px 15px rgba(0,0,0,0.2);cursor:pointer}.navit-char:hover{background-color:#3a3a3a;color:#b6b6b6;box-shadow:0 0 15px rgba(255,255,255,0.2),inset 0 -15px 15px rgba(255,255,255,0.05),0 0 15px rgba(255,255,255,0.2),inset 0 15px 15px rgba(0,0,0,0.2)}.navit-char a{color:#ffffff}.navit-char a:hover{color:#000000}.hidder{display:flex;align-items:center;background-color:#ffffff}.revstuff{margin-right:2rem;color:#000000;cursor:pointer}.info{display:flex;flex-direction:column;text-align:center;margin-top:1.75rem}.info-content{display:flex;justify-content:center;align-items:center}.info-sub{margin-bottom:1.75rem}.info-sub a{color:#000}

.skil-card{width:auto;display:flex;justify-content:center;align-items:center;border:2px solid #000000;padding:0.25rem;margin:0.5rem}.skil-card h3{margin:auto;margin-left:0.5rem}.skil-icon{width:3rem}

.titl a{text-decoration:none;color:black}.titl :hover{color:darkgray;cursor:pointer}.date{margin-top:0.25rem}.charmbar{margin-top:0.25rem}.desc{margin-top:1rem}.buttons{display:flex;justify-content:space-between;margin-top:1rem}

.lead .h5{text-indent:1rem}.lead li{text-indent:1rem}

*{box-sizing:border-box;font-family:"Roboto", sans-serif;scroll-behavior:smooth}body{overflow-x:hidden}.home-body{display:flex;justify-content:center;background-color:rgba(0,0,0,0.7)}.nav-body{width:8rem}.compcard{margin-bottom:1.75rem;border:darkgray solid 1px;border-radius:0.25em}

body{margin:0;font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:#fff}code{font-family:source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace}

