.skil-card {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #000000;
  padding: 0.25rem;
  margin: 0.5rem;
}

.skil-card h3 {
  margin: auto;
  margin-left: 0.5rem;
}

.skil-icon {
  width: 3rem;
}
