.navit-char {
  height: 1rem;
  width: 1rem;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: #272727;
  border: 2px solid #212629;
  border-radius: 1em;
  padding: 1.25rem;
  box-shadow: 0 -15px 15px rgba(255, 255, 255, 0.05),
    inset 0 -15px 15px rgba(255, 255, 255, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.2),
    inset 0 15px 15px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.navit-char:hover {
  background-color: #3a3a3a;
  color: #b6b6b6;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.2),
    inset 0 -15px 15px rgba(255, 255, 255, 0.05),
    0 0 15px rgba(255, 255, 255, 0.2), inset 0 15px 15px rgba(0, 0, 0, 0.2);
}

.navit-char a {
  color: #ffffff;
}

.navit-char a:hover {
  color: #000000;
}

/*      Laptop Styles      */

.hidder {
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

.revstuff {
  margin-right: 2rem;
  color: #000000;
  cursor: pointer;
}

/*    Laptop Styles end    */

/*   Mobile Styles   */

.info {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 1.75rem;
}

.info-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-sub {
  margin-bottom: 1.75rem;

  a {
    color: rgb(0, 0, 0);
  }
}

/*   Mobile Styles End  */
